import React, { useState } from "react";
import { QrReader } from "react-qr-reader";

import renvoq from "./asssets/renvoq.mp4";

const App = () => {
  const [data, setData] = useState("No result");

  return (
    <>
      <QrReader
        constraints={{
          facingMode: "environment",
        }}
        onResult={(result, error) => {
          if (!!result) {
            let text = result?.text ?? "";
            const params = new URLSearchParams(
              text.substring(text.indexOf("?"))
            );
            setData(params.get("video"));
          }
          if (!!error) {
            console.info("ERROR", error);
          }
        }}
        videoStyle={{
          position: "fixed",
          top: 0,
          left: 0,
          height: "100vh",
          width: "100vw",
          objectFit: "cover",
        }}
      />
      {data === "renvoq" && (
        <video
          playsInline
          autoPlay
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100vw",
          }}
          controls
        >
          <source src={renvoq} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </>
  );
};

export default App;
